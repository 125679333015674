import $ from 'jquery';
import whatInput from 'what-input';
import jPlayer from 'jPlayer';
import videojs from 'video.js';

window.$ = $;


import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


Foundation.Abide.defaults['validators']['checkbox_limit'] =
  function($el, required, parent) {
    var group = parent.closest('.checkbox-group');
    var min = group.attr('data-validator-min');
    var checked = group.find(':checked').length;
    if (checked >= min) {
      // clear label highlight
      group.find('label').each(function() {
        $(this).removeClass('is-invalid-label');
      });
      // clear checkbox error 
      group.find(':checkbox').each(function() {
        $(this).removeClass('is-invalid-input').removeAttr('data-invalid');
      });
      group.find('small.form-error').hide();
      return true;
    } else {
      // label highlight
      group.find('label').each(function() {
        $(this).addClass('is-invalid-label');
      });
      // checkbox error 
      group.find(':checkbox').each(function() {
        $(this).addClass('is-invalid-input').attr('data-invalid');
      });
      group.find('small.form-error').show();
      return false;
    }
  };
// Set paramaters
Foundation.Abide.defaults.patterns['aantalouders'] = /^5$/;
Foundation.Abide.defaults.patterns['titelwoord'] = /^[Nn][Aa][Bb][Ii][Jj]$/;
Foundation.Abide.defaults.patterns['titelwoord1'] = /^[Aa][Nn][Dd][Ee][Rr][Ss]$/;
Foundation.Abide.defaults.patterns['paswoord'] = /^(.){8,}$/;


$(document).foundation();


function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);
  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

